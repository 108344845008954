import React from 'react';
import YouTube from 'react-youtube';

const SIMULATOR_REGISTER_VIDEO_ID = 'gvYAA3Xe9T8';

const VIDEO_OPTIONS = {
  playerVars: {
    autoplay: 1,
  },
};
const SimulatorRegisterVideo = () => (
  <YouTube
    videoId={SIMULATOR_REGISTER_VIDEO_ID}
    opts={{ ...VIDEO_OPTIONS, width: '100%', height: '100%' }}
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
  />
);

export default SimulatorRegisterVideo;

import React from 'react';
import LinkedIn from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';

const LinkedInIcon = () => (
  <IconButton
    id="linked-in-icon-button"
    aria-label="LinkedIn IconButton"
    sx={{ color: '#0077B5' }}
  >
    <LinkedIn fontSize="40px" color="white" padding="8px" />
  </IconButton>
);

export default LinkedInIcon;
